.cardCompany {
  cursor: pointer;
  border: 1px solid #fff;
  color: var(--primary);
  transition: .5s;
}

.cardCompany:hover, .selected {
  border: 1px solid var(--primary);
  color: var(--primary);
}
